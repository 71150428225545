/**
 * App Body File.
 *
 * created by Ty DeMarcus Kennedy 10/4/2022
 */
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavBar } from "../ui/navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  faSprayCan,
  faTruck,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormComponent } from "../ui/form";

export default function Home() {
  const services = [
    {
      icon: faUserGear,
      name: "Tire Repair",
      img: process.env.PUBLIC_URL + "/tire.png",
      fact: "Need a quick tire change on the side of the road? Our app connects you with reliable providers who can get you back on the road safely and quickly.",
    },
    {
      icon: faSprayCan,
      name: "Battery Service",
      img: process.env.PUBLIC_URL + "/battery.png",
      fact: "Dead battery? No problem. Our app connects you with experienced providers who can jump-start your car and get you on your way in no time.",
    },
    {
      icon: faTruck,
      name: "Towing",
      servicers: 3000,
      img: process.env.PUBLIC_URL + "/tow.png",
      fact: "Stuck on the side of the road? No problem. Our app connects you with trusted providers who can tow your vehicle and get you back on the road.",
    },
  ];

  const hasAnchorTag = () => {
    const hash = window.location.hash;
    const buttonElement = document.getElementById(
      `${hash.replace("#", "")}-button`
    );
    // Simulate a click event on the element
    if (buttonElement) buttonElement.click();
  };

  useEffect(() => {
    hasAnchorTag();
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <NavBar />
      {/* Hero Section */}
      <Container fluid style={{ marginTop: 110 }}>
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <video width='100%' controls={true} loop>
              <source
                src={`${process.env.PUBLIC_URL}/promo.mp4`}
                type='video/mp4'
              />
            </video>
          </Col>
        </Row>
      </Container>
      {/* How We Can help section 1 */}
      <Container
        fluid
        style={{ backgroundColor: "white", padding: "100px 20px" }}
        id='info'
      >
        <Row>
          <Col md={2} />
          <Col md={3}>
            <div style={{ textAlign: "center" }}>
              <img
                src={`${process.env.PUBLIC_URL}/lockoutscreenshot.png`}
                style={{
                  maxWidth: "225px",
                  borderRadius: 9,
                }}
              ></img>
            </div>
          </Col>
          <Col
            md={5}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div id='help-section-1'>
              <h1>Safe Service For All</h1>
              <h5 style={{ color: "rgb(158 39 39)" }}>
                Connecting customers and service providers for a safer and more
                convenient experience
              </h5>
              <div style={{ paddingTop: "50px" }}>
                <h4 style={{}}>
                  Our app connects you with trusted service providers for a
                  hassle-free experience. Download now and enjoy peace of mind
                  knowing that your automotive needs are in good hands.
                </h4>
                <br />
                <br />
              </div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                maxWidth: "600px",
                width: "600px",
                height: "300px",
                boxShadow: "0px 1px 13px #111",
                padding: "40px 10px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div style={{ marginBottom: 15 }}>
                <h1>Your Safer Roadside Solution</h1>
                <h5>September 2023</h5>
                <h5>Contact us to become an early adopter</h5>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center", margin: 10 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/appStoreLink.png`}
                    style={{
                      width: "120px",
                      boxShadow: "0px 1px 3px #111",
                      borderRadius: 9,
                    }}
                  ></img>
                </div>
                <div style={{ textAlign: "center", margin: 10 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/google.png`}
                    style={{
                      width: "120px",
                      boxShadow: "0px 1px 3px #111",
                      borderRadius: 9,
                    }}
                  ></img>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Services Provided Section */}
      <Container style={{ padding: "100px 20px" }}>
        <Row>
          <Col style={{ paddingBottom: 50 }}>
            <h3 style={{}}>Popular Services Near You</h3>
            <h5 style={{ color: "#1653cb" }}>
              Sign up as a Service Provider or Service Requester
            </h5>
          </Col>
        </Row>
        <Row>
          {services.map((s, idx) => (
            <Col md={4} className='provi-service'>
              <div>
                <img
                  src={s.img}
                  style={{
                    backgroundPosition: "center",
                    width: "100%",
                    maxHeight: 300,
                    height: 300,
                    boxShadow: "0px 1px 3px #111",
                  }}
                ></img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: 50,
                  }}
                >
                  <h4>{s.name}</h4>
                  <h6>{s.fact}</h6>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      {/* promo video 2 */}
      <Container
        style={{ paddingTop: 150, paddingBottom: 150 }}
        id='contact-us'
      >
        <Row>
          <Col style={{ paddingBottom: 50 }}>
            <h1 style={{ color: "#1653cb" }}>Partner With Us</h1>
          </Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col lg={5} style={{ padding: 40 }}>
            <div
              style={{
                backgroundColor: "white",
                maxWidth: "600px",

                boxShadow: "0px 1px 13px #111",
                padding: "40px 40px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FormComponent />
            </div>
          </Col>
          <Col lg={7} style={{ padding: 0 }}>
            <video width='100%' controls={true} muted loop>
              <source
                src={`${process.env.PUBLIC_URL}/promo2.mp4`}
                type='video/mp4'
              />
            </video>
          </Col>
        </Row>
      </Container>
      {/* How We Can help section 2 */}
      <Container
        fluid
        style={{ padding: "100px 20px", backgroundColor: "#e7e7e7" }}
      >
        <Row>
          <Col md={2}></Col>
          <Col md={4}>
            <h1 style={{ color: "black" }}>
              Convenient Auto Service for Any Occasion
            </h1>
            <h5 style={{ color: "rgb(158 39 39)" }}>
              The ultimate solution for efficient automotive service scheduling
            </h5>
            <div style={{ paddingTop: "50px" }}>
              <h4>
                Whether you're a customer looking to schedule a maintenance
                appointment or a service provider managing your business hours,
                our app is the ultimate solution for efficient automotive
                service scheduling. Download our app today and experience the
                benefits for yourself
              </h4>
              <br />
              <br />
            </div>
          </Col>
          <Col md={4}>
            <div style={{ display: "flex" }}>
              <img
                src={`${process.env.PUBLIC_URL}/group1.png`}
                style={{
                  maxWidth: "225px",
                }}
              ></img>
              <img
                src={`${process.env.PUBLIC_URL}/group2.png`}
                style={{
                  maxWidth: "225px",
                }}
              ></img>
            </div>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>

      {/* How We Can help section 3 */}
      {/* <Container
        fluid
        style={{ backgroundColor: "#e7e7e7", padding: "50px 0px 50px 0px" }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col md={2}></Col>
          <Col md={4}>
            <div style={{ display: "flex" }}>
              <img
                src={`${process.env.PUBLIC_URL}/lockoutscreenshot.png`}
                style={{ maxWidth: "225px" }}
              ></img>
              <img
                src={`${process.env.PUBLIC_URL}/lockoutscreenshot.png`}
                style={{ maxWidth: "225px" }}
              ></img>
            </div>
          </Col>
        </Row>
      </Container> */}
      {/* contact section */}
      <Container
        fluid
        style={{
          backgroundColor: "white",
          padding: "50px 20px",
          color: "black",
        }}
      >
        <Row
          style={{
            minHeight: "200px",
            alignContent: "center",
          }}
        >
          <Col sm={3}></Col>
          <Col sm={2} className='center-all'>
            <h3>Get The App</h3>
            <p>
              Download our app from the Apple App Store or the Google Play Store
            </p>
            <div className='center-all' style={{ display: "flex" }}>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/appStoreLink.png`}
                  style={{ maxWidth: "100px", minWidth: "100px" }}
                ></img>
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/google.png`}
                  style={{ maxWidth: "100px", minWidth: "100px" }}
                ></img>
              </div>
            </div>
          </Col>
          <Col sm={2} className='center-all' id='contact-us-container'>
            <h3>Contact Us</h3>
            <div id='email-container'>
              <p
                style={{
                  borderBottom: "solid 1.5px yellow",
                  fontSize: "10pt",
                  color: "black",
                  alignItems: "center",
                }}
              >
                ea_1_Assistance@yahoo.com
              </p>
              <p
                style={{
                  borderBottom: "solid 1.5px yellow",
                  fontSize: "10pt",
                  color: "black",
                }}
              >
                admin.support@ecare-software.com
              </p>
            </div>
          </Col>
          <Col sm={2} className='center-all'>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/logo.png`}
                style={{
                  maxWidth: "100px",
                  minWidth: "100px",
                  marginRight: "10px",
                }}
              ></img>
              <img
                src={`${process.env.PUBLIC_URL}/ecarelogo.png`}
                style={{ maxWidth: "100px", minWidth: "100px" }}
              ></img>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
