import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./style.css";
import axios from "axios";

const FormComponent = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [details, setDetails] = useState("");
  const [message, setMessage] = useState({ good: true, message: "" });

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      setMessage({ message: "", good: false });
      const { data, headers } = await axios(
        "https://ea1-dev.azurewebsites.net/api/earlyAdopter",
        {
          method: "POST",
          data: {
            name,
            email,
            serviceType,
            details,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMessage({ message: "Success!", good: true });

      setTimeout(() => {
        setMessage({ message: "", good: true });
        setName("");
        setEmail("");
        setServiceType("");
        setDetails("");
      }, 4000);
    } catch (e) {
      console.log(e);
      setMessage({ message: "Error Submitting form", good: false });
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Group controlId='formName'>
        <Form.Label>Name or Company Name:</Form.Label>
        <Form.Control
          type='text'
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId='formEmail'>
        <Form.Label>Preferred Email:</Form.Label>
        <Form.Control
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId='formServiceType'>
        <Form.Label>Service Type:</Form.Label>
        <Form.Control
          as='select'
          value={serviceType}
          onChange={(e) => setServiceType(e.target.value)}
          required
        >
          <option value=''>Select Service Type</option>
          <option value='Window Repair'>Window Repair</option>
          <option value='Gas and Fuel'>Gas and Fuel</option>
          <option value='Tire Repair'>Tire Repair</option>
          <option value='Tire Repair'>Engine Repair</option>
          <option value='Tire Repair'>Battery</option>
          <option value='Misc. Service'>Misc. Service</option>
          <option value='Towing'>Towing</option>
          <option value='Other'>Other</option>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId='formDetails'>
        <Form.Label>Details:</Form.Label>
        <Form.Control
          as='textarea'
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group style={{ minHeight: 50 }}>
        {message.message && (
          <Form.Label className={message.good ? "text-success" : "text-danger"}>
            {message.message}
          </Form.Label>
        )}
      </Form.Group>

      <Button variant='primary' type='submit'>
        Submit
      </Button>
    </Form>
  );
};

export default FormComponent;
