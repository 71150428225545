import * as React from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { NavBar } from "./components/ui/navbar/Navbar";
import Home from "./components/pages/Home";

export default function EAOneApp() {
  const location = useLocation();
  const hideNavOnRoutes = ["/"];

  return (
    <div className='eaOneApp'>
      {!hideNavOnRoutes.includes(location.pathname) && <NavBar />}
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
    </div>
  );
}
